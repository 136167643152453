import { qsRequired } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class ShowMore extends UcoastEl {
	static htmlSelector = 'show-more'
	elements: {
		details: HTMLDetailsElement
		opener: HTMLElement
		content: HTMLElement
	}
	constructor() {
		super()
		this.elements = this.getElements()
	}

	override connectedCallback() {
		super.connectedCallback()
		this.init()
	}
	init() {
		if (this.elements.opener) {
			this.elements.opener.removeEventListener('click', this.expandShowMore.bind(this))
		}
		this.elements = this.getElements()
		this.elements.opener.addEventListener('click', this.expandShowMore.bind(this))
	}
	getElements() {
		const details = qsRequired<HTMLDetailsElement>('details', this)
		const opener = qsRequired('summary, button', this)
		const content = qsRequired('[data-uc-show-more-content]', this)
		return {
			details,
			opener,
			content,
		}
	}
	expandShowMore(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		if (this.elements.details.hasAttribute('open')) {
			this.elements.content.style.maxHeight = `0px`
			window.setTimeout(() => {
				this.elements.details.removeAttribute('open')
			}, 300)
		} else {
			this.elements.details.setAttribute('open', '')
			this.elements.content.style.maxHeight = `${this.elements.content.scrollHeight}px`
		}
	}
}

